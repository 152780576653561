@import '~antd/dist/antd.css';
.App {
  text-align: center;
}
/* body{
  background-image: src="../bg.png";
} */
@font-face {
  font-family: DB;
  src: url('/DB/DB Helvethaica X v3.2.ttf');
  
}
thead[class*="ant-table-thead"] th{
  background-color: #27AA9C !important;
  color: #ffffff;
}
tbody[class*="ant-table-tbody"] tr{
  background-color: #ffffff !important;
}
.ant-layout-sider{
  background-image: url(/bg_rotate_crop.jpg);
  font-family: DB;
}

.ant-menu{
  background: transparent;
  font-size: 20px;
}
.ant-card-bordered{
  border: none;
}
.ant-layout-header{
  height: 64px;
  padding: 0px;
  line-height: 64px;
  background: transparent;
}
.ant-menu-item > a,li{
  color: rgba(255, 255, 255, 0.95);
}
.ant-menu-submenu-active{
  color: #ffffff;
}
.ant-menu-submenu > .ant-menu{
  background-color:transparent
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.labelform {
  font-size: 20px;
  font-weight: bold;
  color: rgb(39, 170, 156);
}
.headercolor {
  font-size: 18px;
}
.ant-menu-item-selected {
  border-style: solid;
  width: 95% !important;
  border-radius: 20px;
  border-color: #fff;
  background-color: transparent !important;
}
.ant-menu-item::after {
  border-right:transparent !important;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
